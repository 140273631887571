module.exports = {
  siteTitle: 'Whitney Lampkin', // <title>
  manifestName: 'Whitney Lampkin',
  manifestShortName: 'WhitneyL', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/2022 Profile Pic - Copy 2.jpg',
  pathPrefix: `/whitney-lampkin-resume/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Whitney',
  lastName: 'Lampkin',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/company/codingjewels',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/whitneylampkin',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/codingjewels',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/codingjewels',
    },
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      url: 'https://youtube.com/channel/UCXvX6zPStkjcUXHBL27u1Ow?view_as=subscriber',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/codingjewels',
    },
    {
      icon: 'fa-codepen',
      name: 'Codepen',
      url: 'https://codepen.io/WLampkin',
    },
    {
      icon: 'fa-stack-overflow',
      name: 'Codepen',
      url: 'https://stackoverflow.com/users/6181733/ms-whitney',
    },
  ],
  email: 'contact@whitneylampkin.com',
  phone: '',
  address: 'Dallas, TX, United States',
};
