import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            "The pessimist looks down and hits his head. The optimist looks up and loses his footing. 
            The realist looks forward and adjusts his path accordingly." I am a realist. 
            The ability to "look forward and adjust my path accordingly" has allowed me to build a 
            promising career as a software developer and Microsoft Certified Professional.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
          <div style={{ marginTop: '100px'}}>
            <div className="subheading mb-5">
              Personal Websites · <a href={`https://codingjewels.blog`}>Coding Jewels Blog</a>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer II</h3>
              <div className="subheading mb-3">Microsoft</div>
              <p>
                <ul>
                  <li>Site Reliability Engineer (SRE) entrusted with ensuring the reliability, availability, and performance of the Azure Kubernetes Service (AKS) platform, one of Microsoft’s most critical cloud services</li>
                  <li>Authored and implemented new Service Level Indicators (SLIs) to monitor and measure performance and reliability</li>
                  <li>Incident response and mitigation for various issues that arise for customers who rely on our virtualization technologies</li>
                  <li>Contributed to refactoring of the AKS codebase to optimize and improve unit test performance, reducing the test runtime by ~50%</li>
                  <li><b>Leveraging Knowledge</b> in Distributed Systems, Golang, Kusto Querying Language KQL, Prometheus, Azure, Kubernetes, Docker, Containers, SLAs/SLOs/SLIs, Azure DevOps, Agile Methodologies, Cloud Computing, Technical Writing, Markdown, Virtual Machines (VMs), Ginkgo Unit Testing Framework, Interviewing and Candidate Assessment</li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">February 2022 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Senior Content Developer</h3>
              <div className="subheading mb-3">Microsoft</div>
              <p>
                <ul>
                  <li>Senior content developer for the Microsoft Learn online training platform</li>
                  <li>Responsible for creating compelling, interactive content for Azure cloud services and developing internal training content used to onboard new content developers</li>
                  <li>Performed industry-wide competitive analysis research for the top learning platforms in the field</li>
                  <li><b>Leveraged Knowledge</b> in Technical Writing, Instructional Design, Cloud Computing, Azure, IoT, Competitive Analysis, PowerPoint, Markdown</li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">January 2021 - February 2022</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Senior Software Developer</h3>
              <div className="subheading mb-3">Allsynx</div>
              <p>
                <ul>
                  <li>Full-stack software engineer in the insurance industry</li>
                  <li>Lead frontend developer for Allsynx’s benefits enrollment platform, THEbenefitsHUB</li>
                  <li>Developed a user-friendly frontend application that streamlined and simplified the employee benefits enrollment process, empowering employees to easily enroll in their company's benefit programs</li>
                  <li>Collaborated closely with junior developers and QA testers, providing guidance and mentorship where needed to ensure the successful completion of projects and the professional growth of team members</li>
                  <li><b>Leveraged Knowledge</b> in .Net Framework, C#, APIs, Unit Testing, Jest Testing Framework, React, Redux, Redux-Saga, GraphQL, Identity Server, Git, Azure DevOps, Agile Methodologies, Documentation, Markdown</li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">March 2019 - January 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer</h3>
              <div className="subheading mb-3">Switch Commerce</div>
              <p>
                <ul>
                  <li>Full-stack software developer in the ATM transaction processing industry</li>
                  <li>Engineered and maintained web services and APIs for processing bank transactions, providing secure and efficient processing while adhering to industry compliance standards and protocols</li>
                  <li>Conducted comprehensive Quality Assurance (QA) testing of my code on ATM systems, ensuring 99.999% uptime</li>
                  <li>Developed notification feature for the Terminal Management System to inform ATM owners of important updates</li>
                  <li>Utilized Wireshark to monitor, analyze and troubleshoot network traffic between devices processing bank transactions ensuring optimal performance and security </li>
                  <li><b>Leveraged Knowledge</b> in .Net Framework, C#, SQL, JavaScript, React, Redux, Sagas, Relational Databases, Debugging, Unit Testing, Network Monitoring and Troubleshooting, Internet Information Services (IIS), Windows Servers, Microsoft Messaging Queue (MSMQ), Agile Methodologies</li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2017 - February 2019</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software Developer</h3>
              <div className="subheading mb-3">Exigo Office</div>
              <p>
                <ul>
                  <li>Backend software engineer for Exigo’s direct selling software platform</li>
                  <li>Designed, developed and maintained custom large-scale software solutions for MLM clients</li>
                  <li>Wrote a custom software solution based on an existing dynamic order processing feature to accurately calculate order totals, including taxes, that allowed our client to successfully launch in new international markets</li>
                  <li>Collaborated directly with Project Managers to ensure solutions adhered to the customer’s design specifications</li>
                  <li><b>Leveraged Knowledge</b> in .Net Framework, C#, SQL, Git</li>
                </ul>
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">February 2017 - October 2017</span>
            </div>
          </div>

          {/* <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Developer Intern (Programming Bootcamp)</h3>
              <div className="subheading mb-3">EpicU</div>
              <p>
                Collaboratively administrate empowered markets via plug-and-play
                networks. Dynamically procrastinate B2C users after installed
                base benefits. Dramatically visualize customer directed
                convergence without revolutionary ROI.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">September 2016 - December 2016</span>
            </div>
          </div> */}

          {/* <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Developer Apprentice</h3>
              <div className="subheading mb-3">Learn Visual Studio Net</div>
              <p>
                Full-stack software developer apprentice. Learned the fundamentals of 
                object-oriented programming using .Net technologies.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">October 2015 - January 2016</span>
            </div>
          </div> */}
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">University of Texas at Dallas</h3>
              <div className="subheading mb-3">Chemistry, B.S.</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">December 2021</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Nacogdoches High School</h3>
              <div className="subheading mb-3">High School Diploma</div>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2005</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">Skills</h2>

          <div className="subheading mb-3">
            Programming Tools
          </div>
          <ul className="list-inline dev-icons">
            <li className="list-inline-item">
              <i className="fab fa-html5"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-css3-alt"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-js-square"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-react"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-node-js"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-wordpress"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-bitbucket"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-npm"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-codepen"></i>
            </li>
            <li className="list-inline-item">
              <i className="fab fa-git"></i>
            </li>
          </ul>

          <div className="subheading mb-3">Workflow</div>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-check"></i>
              Responsive Design
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Trunk-Based Development
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Cross Functional Teams
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Agile Development &amp; Scrum
            </li>
          </ul>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <p>
            When I'm not busy writing exciting new code, I like to spend my time 
            playing chess, challenging myself with nonogram puzzles, reading self-help books
            and playing tennis.
          </p>
          <p className="mb-0">
            Some of my other passions include: being a great mom, sharing the world of software
            development with others and striving to be the best human I can be.
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Certifications</h2>
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              Microsoft Certified: Azure Fundamentals 
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              Microsoft Certified Professional: Programming in C# (Credential ID G348-5956) 
            </li>
            <li>
              <i className="fa-li fa fa-trophy text-warning"></i>
              Microsoft Global Hackathon: Participant (2022 & 2023)
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
